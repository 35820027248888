import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ReportModels } from 'models/ReportModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ReportService extends BaseApiService<ReportModels.Report> {
    constructor(httpClient: AuthHttpClient, private i18n: I18N) {
        super(httpClient, ReportModels.Report);
    }

    // api/reports/nets/65/cert/download
    public getCertificateReport(netId: number): Promise<any> {
        return this.getBlob(`nets/${netId}/cert/download?culture=${this.getLocale()}`);
    }

    public getProductionOrderReport(netId: number): Promise<any> {
        return this.getBlob(`nets/${netId}/prod/download?culture=${this.getLocale()}`);
    }

    public getSpecificationReport(netId: number): Promise<any> {
        return this.getBlob(`nets/${netId}/spec/download?culture=${this.getLocale()}`);
    }

    public getDrawingReport(netId: number): Promise<any> {
        return this.getBlob(`nets/${netId}/drawings/download?culture=${this.getLocale()}`);
    }



    public getServiceCard(serviceId: number): Promise<any> {
        return this.getBlob(`services/${serviceId}/servicecard/download?culture=${this.getLocale()}`);
    }

    public getServiceExtendedPeriod(serviceId: number): Promise<any> {
        return this.getBlob(`services/${serviceId}/extendedperiod/download?culture=${this.getLocale()}`);
    }

    public getServiceSummaryReport(serviceId: number): Promise<any> {
        return this.getBlob(`services/${serviceId}/servicesummary/download?culture=${this.getLocale()}`);
    }

    public getServiceSummaryReportHtml(serviceId: number): Promise<any> {
        return this.getResponse(`services/${serviceId}/servicesummary?culture=${this.getLocale()}`)
            .then(response => {
                return response.text().then((responseText) => {
                    if (responseText) {
                        return responseText;
                    }
                    return null;
                })
            });
    }

    public getServiceNotesReport(serviceId: number): Promise<any> {
        return this.getBlob(`services/${serviceId}/servicenotes/download?culture=${this.getLocale()}`);
    }

    public getServiceNotesReportHtml(serviceId: number): Promise<any> {
        return this.getResponse(`services/${serviceId}/servicenotes?culture=${this.getLocale()}`)
            .then(response => {
                return response.text().then((responseText) => {
                    if (responseText) {
                        return responseText;
                    }
                    return null;
                })
            });
    }

    private getLocale(): string {
        let locale = this.i18n.getLocale();
        if (locale === 'nb-NO') {
            locale = 'nb';
        }

        return locale;
    }
}
