import { autoinject } from 'aurelia-framework';
import { RoleModels } from 'models/RoleModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class RoleService extends BaseApiService<RoleModels.Role> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, RoleModels.Role);
    }

    public addUserRole(userId: string, rolename: string): Promise<any> {
        return this.post(null, `/adduser/${rolename}/${userId}`);
    }

    public removeUserRole(userId: string, rolename: string): Promise<any> {
        return this.delete(null, `/remove/${rolename}/${userId}`);
    }
}
