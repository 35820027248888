import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ReinforcementService extends BaseApiService<Models.Reinforcement> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.Reinforcement);
  }

  public getReinforcementStrengthRuleByNetId(netId: number, halfMeshSize: number): Promise<any> {
    return this.get(`get-reinforcement-strength-rule-for-net/${netId}/${halfMeshSize}`);
  }
}
