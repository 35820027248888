import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class RopeService extends BaseApiService<Models.Rope> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.Rope);
    }

    public calculateMinBreakingStrength(dimensionClassId: number): Promise<any> {
      return this.get('calculate-minimum-breaking-strength/' + dimensionClassId);
    }

    public validateRopes(netId: number): Promise<any> {
        return this.get('validate-ropes/' + netId);
    }

    public getSuggestedRopeTypes(netId: number): Promise<any> {
        return this.get('get-suggested-rope-types/' + netId);
    }

    public getCurrentRopes(netId: number): Promise<any> {
        return this.get('get-current-rope-types/' + netId);
    }

    public saveRopesForNet(netId: number, currentRopes: any): Promise<any> {
        return this.post(currentRopes, '/save-current-rope-types/' + netId);
    }
}
