import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class RopeTypeService extends BaseApiService<Models.RopeType> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.RopeType);
    }

    public async getAllCached(): Promise<Array<Models.RopeType>> {
        return super.getAllCached()
            .then(res => {
                res = res.sort(function(a,b) {return (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0);} );
                this.cachedObjects = res;

                return res;
            });
    }

    public getValidRopeTypes(netId: number): Promise<Array<any>> {
        return this.getAll('/get-valid-rope-types/' + netId);
    }
}
